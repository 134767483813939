<template>
  <vx-form
    v-slot="{ loading }"
    :resource="formResource"
    @submit="handleSubmit"
  >
    <vx-select-resource
      v-model="formValue.text_content_id"
      :rules="rules.text_content_id"
      :resource="contentResource"
      :reduce="({ id }) => id"
      options-label="title"
      name="text_content_id"
      label="Content"
    />

    <vx-select
      v-model="formValue.is_active"
      :rules="rules.is_active"
      :options="boolStatusesOptions"
      :reduce="({ value }) => value"
      options-label="label"
      name="active"
      label="Active"
    />

    <vx-button
      :loading="loading"
      variant="primary"
      type="submit"
    >
      Save
    </vx-button>
  </vx-form>
</template>

<script>
import { contents, notifications, passDataToResource } from '@/services/resources'
import { boolStatusesOptions } from '@/services/utils'
import { VxButton } from '@/components/buttons'
import { VxForm, VxSelectResource, VxSelect } from '@/components/form'
import { reactive } from '@vue/composition-api/dist/vue-composition-api'
import { setValuesToForm } from '@/services/form'

export default {
  name: 'NotificationEditor',
  components: {
    VxForm,
    VxSelect,
    VxSelectResource,
    VxButton
  },
  props: {
    notification: {
      type: Object,
      default: null
    }
  },
  setup ({ notification }, { emit }) {
    const isEdit = !!notification

    const backToFrontMapper = ({ text_content_id, is_active }) => {
      return {
        text_content_id,
        is_active: is_active ? boolStatusesOptions['1'].value : boolStatusesOptions['0'].value
      }
    }

    const frontToBackMapper = ({ text_content_id }) => {
      return {
        text_content_id,
        is_active: formValue.is_active
      }
    }

    const formResource = isEdit
      ? passDataToResource(notifications.update, {
        frontToBackMapper,
        requestParams: { urlParams: { id: notification.id } }
      })
      : passDataToResource(notifications.create, {
        frontToBackMapper
      })

    const formValue = reactive({
      text_content_id: null,
      is_active: null
    })

    const rules = {
      text_content_id: {
        required: true
      },
      is_active: {
        required: true
      }
    }

    if (isEdit) setValuesToForm(backToFrontMapper(notification), formValue)

    const handleSubmit = ([, res]) => {
      if (res) {
        emit('submit', res)
      }
    }

    return {
      formValue,
      formResource,
      rules,
      handleSubmit,
      boolStatusesOptions,
      contentResource: contents.getAll
    }
  }
}
</script>
