<template>
  <div>
    <vx-page-title-actions>
      <vx-button
        :can="canCreate"
        variant="primary"
        @click="openModal()"
      >
        Add
      </vx-button>
    </vx-page-title-actions>
    <vx-table
      ref="tableRef"
      :resource="resource"
      :columns="columns"
      :prefetch="getContentData"
    />
    <b-modal
      v-model="modalIsOpen"
      :title="modalTitle"
      hide-footer
      title-tag="h4"
      @close="closeModal"
    >
      <notification-editor :notification="entity" @submit="handleSubmit" />
    </b-modal>
  </div>
</template>

<script>
import { VxTable, VxCellButtons, VxCellDate } from '@/components/table'
import { contents, notifications, passDataToResource, useResource } from '@/services/resources'
import VxPageTitleActions from '@/components/layout/VxPageTitleActions'
import { VxButton } from '@/components/buttons'
import { useModalEditor } from '@/services/modal'
import { ref, computed } from '@vue/composition-api'
import { BModal } from 'bootstrap-vue'
import NotificationEditor from '@/views/texts/NotificationEditor'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'

export default {
  name: 'Notifications',
  components: {
    VxTable,
    VxPageTitleActions,
    VxButton,
    VxCellButtons,
    VxCellDate,
    NotificationEditor,
    BModal
  },
  setup () {
    const tableRef = ref(null)
    const contentList = ref([])

    const resource = notifications.getAll

    const { modalIsOpen, entity, openModal, closeModal } = useModalEditor()

    const { can: canCreate } = useResource(notifications.create)
    const { can: canUpdate } = useResource(notifications.update)

    const modalTitle = computed(() => {
      return (entity.value ? 'Edit' : 'Create') + ' Notification'
    })

    const handleSubmit = () => {
      closeModal()
      tableRef.value.refresh()
    }

    const getContentData = async () => {
      const { can, callRequest } = useResource(contents.getAll)

      if (can) {
        const [, res] = await callRequest({ params: null })
        contentList.value = res.data
      }
    }

    const statusButton = ({ id, is_active }) => {
      const button = { ...is_active ? buttons.turnOff : buttons.turnOn }

      const resource = passDataToResource(notifications.toggleStatus, {
        requestParams: {
          urlParams: { id }
        }
      })

      return [{ ...button, resource }]
    }

    const actionButtons = ({ id }) => {
      const editButton = {
        ...buttons.edit,
        can: canUpdate,
        onClick: openModal
      }

      const deleteButton = {
        ...buttons.delete,
        resource: passDataToResource(notifications.delete, {
          requestParams: { urlParams: { id } }
        })
      }

      return [editButton, deleteButton]
    }

    const columns = [
      { key: 'id' },
      {
        key: 'text_content_id',
        label: 'Content',
        formatter: (id) => {
          const [content] = contentList.value.filter(item => item.id === id)
          return content?.title || id
        }
      },
      {
        key: 'status',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: statusButton(item)
        })
      },
      {
        key: 'created_at',
        component: VxCellDate
      },
      {
        key: 'updated_at',
        component: VxCellDate
      },
      {
        key: 'actions',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: actionButtons(item)
        })
      }
    ]

    return {
      tableRef,
      resource,
      columns,
      canCreate,
      getContentData,

      entity,
      handleSubmit,

      modalTitle,
      modalIsOpen,
      openModal,
      closeModal
    }
  }
}
</script>
