var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vx-form',{attrs:{"resource":_vm.formResource},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('vx-select-resource',{attrs:{"rules":_vm.rules.text_content_id,"resource":_vm.contentResource,"reduce":function (ref) {
	var id = ref.id;

	return id;
},"options-label":"title","name":"text_content_id","label":"Content"},model:{value:(_vm.formValue.text_content_id),callback:function ($$v) {_vm.$set(_vm.formValue, "text_content_id", $$v)},expression:"formValue.text_content_id"}}),_c('vx-select',{attrs:{"rules":_vm.rules.is_active,"options":_vm.boolStatusesOptions,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"options-label":"label","name":"active","label":"Active"},model:{value:(_vm.formValue.is_active),callback:function ($$v) {_vm.$set(_vm.formValue, "is_active", $$v)},expression:"formValue.is_active"}}),_c('vx-button',{attrs:{"loading":loading,"variant":"primary","type":"submit"}},[_vm._v(" Save ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }